import React, { FC, useState, useMemo } from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'layout';
import sortBy from 'lodash/sortBy';
import Banner from 'components/Banner';
import PageIntroduction from 'components/PageIntroduction';
import FilterComponent from 'components/FilterComponent';
import ProductItem from 'components/ProductItem';
import Pagination from 'components/Pagination';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { IProductListingPage } from './model';
import './ProductListingPageMain.scss';
import './ProductListingPageRTL.scss';

const ProductListingPage: FC<IProductListingPage> = ({
  data: {
    page,
    allUmbracoProductListing,
    allUmbracoProducts,
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
  },
}) => {
  const { nodes: productsCategories } = allUmbracoProductListing;
  const { nodes: products } = allUmbracoProducts;
  const {
    filterDefaultText,
    buttonText,
    buttonAriaLabel,
    productsAmountTitle,
    masterTag,
    banner: [{ properties: banner }],
    url,
    pageIntroduction,
    productsDisclaimer,
    seoMetaDescription,
    seoMetaKeywords,
    seoMetaTitle,
    seoExternalHreflangs,
    defaultCompositions,
    currentCategory,
    defaultCompositions: {
      commonSettings: {
        blocks: [
          {
            properties: { nextAria, prevAria },
          },
        ],
      },
    },
  } = page;

  const { isMobile, isTablet, isMiddleTablet } = useScreenRecognition();

  const productsPerPage = isMobile ? 3 : isMiddleTablet ? 4 : 6;

  const [currentPage, setCurrentPage] = useState<number>(0);
  const productsbySelectedCategory: any = [];

  products.forEach((el) =>
    el.properties.tags.forEach((item) => {
      if (item.name === masterTag[0].name) {
        productsbySelectedCategory.push(el);
      }
    })
  );

  const pagesAmount = useMemo(
    () => Math.ceil(productsbySelectedCategory.length / productsPerPage),
    [productsbySelectedCategory.length, productsPerPage]
  );

  const sortedProductsBySelectedCategory = useMemo(() => {
    const shouldBeSorted = productsbySelectedCategory?.some(({ properties: { order } }) => order);
    if (!shouldBeSorted) {
      return productsbySelectedCategory;
    }

    return sortBy(productsbySelectedCategory, ({ properties: { order } }) => order);
  }, [productsbySelectedCategory]);

  const sortedProductsCategories = useMemo(() => {
    const shouldBeSorted = productsCategories?.[0].categories.some(({ order }) => order);
    if (!shouldBeSorted) {
      return productsCategories;
    }

    return [{ categories: sortBy(productsCategories[0].categories, ({ order }) => order) }];
  }, [productsCategories]);

  const lastProduct: number = useMemo(() => +productsPerPage * (+currentPage + 1), [
    productsPerPage,
    currentPage,
  ]);
  const firstProduct: number =
    +lastProduct - +productsPerPage <= 0 ? 0 : +lastProduct - +productsPerPage;

  const lastHorizontalLineonMobilePage: number = useMemo(
    () => lastProduct - productsPerPage * currentPage,
    [lastProduct, productsPerPage, currentPage]
  );

  let bannerImage = banner.imageLg;

  if (isMobile && banner.imageSm) {
    bannerImage = banner.imageSm;
  } else if (isTablet && banner.imageMd) {
    bannerImage = banner.imageMd;
  }

  return (
    <Layout
      {...{ handleActiveListPage: setCurrentPage, defaultCompositions }}
      disclaimerDesktopImage={disclaimerDesktopImage}
      disclaimerMobileImage={disclaimerMobileImage}
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoExternalHreflangs,
      }}
    >
      <Banner
        inFirstViewPort
        title={banner.title}
        imageAlt={banner.imageAlt}
        image={bannerImage}
        url={url}
        className="product-listing-banner"
        isExtendedBanner={
          banner?.isExtendedBanner ? Boolean(Number(banner?.isExtendedBanner)) : false
        }
      />
      <div className="product-listing-page">
        {pageIntroduction ? (
          <PageIntroduction
            className="product-listing-page__introduction"
            pageIntroduction={pageIntroduction}
          />
        ) : null}
        <div className="product-listing-page__filter-wrapper">
          <h2 className="product-listing-page__amount-products">
            {`${sortedProductsBySelectedCategory.length}
            
            ${productsAmountTitle}`}
          </h2>
          <FilterComponent
            {...{
              masterTag,
              productsCategories: sortedProductsCategories,
              filterDefaultText,
              currentCategory,
            }}
          />
        </div>
        <div className="product-listing-page__product-items-wrapper">
          {sortedProductsBySelectedCategory
            ? sortedProductsBySelectedCategory
                .slice(firstProduct, lastProduct)
                .map(({ properties: productProperties, url: productURL }: any, index: number) => {
                  if (productProperties.title === null) {
                    return;
                  }

                  return (
                    <div
                      className="product-listing-page__item"
                      key={`${productURL}${productProperties.title}`}
                    >
                      {isMobile ? <span className="product-listing-page__horizontal-line" /> : null}
                      <ProductItem
                        {...productProperties}
                        {...{ buttonAriaLabel, buttonText, productURL }}
                      />
                      {isMobile && index + 1 === lastHorizontalLineonMobilePage ? (
                        <span className="product-listing-page__horizontal-line product-listing-page__horizontal-line--last" />
                      ) : null}
                    </div>
                  );
                })
            : null}
        </div>

        {productsDisclaimer ? (
          <PageIntroduction
            className="product-listing-page__disclaimer"
            pageIntroduction={productsDisclaimer}
          />
        ) : null}

        <Pagination
          handleActiveListPage={setCurrentPage}
          pageCount={pagesAmount}
          activePage={currentPage}
          prevContent={prevAria}
          nextContent={nextAria}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($url: String = "", $currentLanguage: String = "", $lang: String) {
    page: umbracoProductListing(url: { eq: $url }, lang: { eq: $lang }) {
      url
      filterDefaultText
      productsAmountTitle
      buttonText
      buttonAriaLabel
      seoMetaDescription
      seoMetaKeywords
      seoMetaTitle
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      currentCategory
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      masterTag {
        name
      }
      pageIntroduction
      productsDisclaimer
      categories {
        url {
          url
          name
        }
      }
      paginationText {
        properties {
          nextPage
          prevPage
        }
      }
      banner {
        properties {
          imageAlt
          title
          imageLg: localImage {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageSm: localImage {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageMd: localImage {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          isExtendedBanner
        }
      }
      productCategories {
        name
      }
    }
    allUmbracoProductListing(filter: { url: { eq: $url } }) {
      nodes {
        categories {
          order
          url {
            url
            name
          }
        }
      }
    }

    allUmbracoProducts(filter: { lang: { eq: $currentLanguage } }) {
      nodes {
        url
        properties {
          sku
          ean
          order
          title
          titleRte
          amountCondoms
          tags {
            name
          }
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default ProductListingPage;
